<template>
  <div class="cw-business-loan-company-list">
    <v-radio-group
      v-model="form.selectedCompany"
      class="mt-0"
    >
      <v-list
        subheader
      >
        <v-subheader
          v-if="companies.length > 1 && validCompanies.length"
          class="pb-4"
        >
          <translate>
            Companies for which you can apply for a loan
          </translate>
        </v-subheader>
        <cw-business-loan-company-list-tile
          v-for="(company, index) in validCompanies"
          :key="`valid-${index}`"
          :form="form"
          :company="company"
        />
        <v-subheader
          v-if="invalidCompanies.length && validCompanies.length"
          class="py-4"
        >
          <translate>
            Companies for which you cannot apply for a loan
          </translate>
        </v-subheader>
        <cw-business-loan-company-list-tile
          v-for="(company, index) in invalidCompanies"
          :key="`invalid-${index}`"
          :form="form"
          :company="company"
        />
      </v-list>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'CwBusinessLoanCompanyList',

  components: {
    'cw-business-loan-company-list-tile':
      () => import('@fi/pages/steps/businessLoan/BusinessLoanCompanyListTile'),
  },

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    companies: {
      type: Array,
      default: () => ([]),
    },
  },

  computed: {
    validCompanies() {
      return this.companies.filter(company => company.valid);
    },
    invalidCompanies() {
      return this.companies.filter(company => !company.valid);
    },
  },
};
</script>

<style lang="scss">
  .cw-business-loan-company-list {
    &__list-item {
      &.active {
        pointer-events: none;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px #7a37d2;
        background: #7a37d2;
      }
    }
    div {
      color: #fff !important;
    }

    .v-subheader {
      height: auto;
    }
  }
</style>
